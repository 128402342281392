import { useInView } from 'react-intersection-observer';

interface IScrollReveal {
    children: React.ReactNode;
}

const ScrollReveal = ({ children }: IScrollReveal) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.2,
    });

    return (
        <div ref={ref} className={`fadeInUp${inView ? ' iv' : ''}`}>
            {children}
        </div>
    );
};

export default ScrollReveal;
