import styled from 'styled-components';

export const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 3rem;
    overflow: hidden;

    @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
        grid-template-columns: 1fr 1fr;
        gap: 1.5rem;
    }
`;

export const Card = styled.div`
    position: relative;

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
        &:nth-child(1) {
            transform: translate(-2.5rem);

            .text {
                padding-left: 7.2rem;
            }
        }

        &:nth-child(2) {
            transform: translate(2.5rem);

            .text {
                padding-right: 7.2rem;
            }
        }
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
        &:nth-child(2n) {
            margin-top: 4.5rem;
        }

        .text {
            max-width: 26rem;
        }
    }
`;
